body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar {
  background-color: black;
  border-bottom: 3px solid rgb(0, 225, 0);
  font-size: 20px;
}
.navbar-collapse {
  float: right;
}
html body {
  background-color: #323639;
  color: #b8c6d1;
}
.inactive {
  color: white;
  text-decoration: none;
}
.circleBase {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: rgb(3, 16, 163);
  background: linear-gradient(
    333deg,
    rgba(3, 16, 163, 1) 0%,
    rgba(26, 117, 190, 1) 100%
  );
  border: 1px solid blue;
  text-align: center;
  vertical-align: center;
  margin: 20px;
}
.circleBase p {
  text-align: center;
  position: relative;
  top: 25%;
  color: silver;
}
label .required {
  color: "red";
}
.carousel img {
  margin: 0 auto;
  text-align: center;
}
.carousel h3 {
  color: black;
}
.carousel-caption {
  background: rgba(1, 1, 1, 0.2);
}
@media (max-width: 768px) {
  .carousel h3 {
    font-size: 90%;
  }
}
